import { useState } from "react"
import styled from "styled-components"

import { useDebouncedCallback } from "use-debounce"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { UpdateAirbnbIntegrationBanner } from "src/components/Integrations/AirbnbUpdate/UpdateAirbnbIntegrationBanner"
import { LinkHomeTable } from "src/components/Integrations/Integration/LinkHomeTable"
import { CreateLinkDialog } from "src/components/Integrations/IntegrationConnectDialogs/CreateLinkDialog"
import { UnlinkDialog } from "src/components/Integrations/IntegrationConnectDialogs/UnlinkDialog"
import {
  NoHomes,
  NoHomesOnFilter,
} from "src/components/Integrations/IntegrationHomesEmptyState"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  usePostIntegrationHomeSearchedEvent,
  usePostIntegrationLinkHomeInitiatedEvent,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { useGetIntegrationLinksData } from "src/data/integrations/hooks/useGetIntegrationLinksData"
import { useTrackIntegrationViewedEvent } from "src/data/integrations/hooks/useSendIntegrationEvents"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

import { GotoMultiAccountSettingsBanner } from "./GotoMultiAccountSettingsBanner"

export function LinkHomesTab({
  integration,
  tabId,
}: {
  integration: TIntegration
  tabId: string
}) {
  const { t } = useTranslate()
  const { orgId } = useOrganization()

  const supportsPagination = integration.pagination_available

  const { limit, offset, setOffset } = useUrlPager({
    initialLimit: HOME_LIST_LIMIT,
  })
  const [showCreateLinkDialog, setShowCreateLinkDialog] = useState(false)
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false)
  const [createLinkProps, setCreateLinkProps] = useState<{
    homeId: string
    homeName: string
    relink: boolean
  }>()
  const [unlinkProps, setUnlinkProps] = useState<{
    homeName: string
    entityName: string
    linkId: string
  }>()

  const {
    linkedIntegrationEntities,
    homeLinks,
    selectableIntegrationEntities,
    sort,
    searchHome,
    searchIntegrationUnit,
    setSort,
    setSearchHome,
    setSearchIntegrationUnit,
    isLoading: loading,
    homeLinksCount,
    cursor,
    fetchNextPage,
  } = useGetIntegrationLinksData({
    integration,
    orgId,
    supportsPagination,
    limit,
    offset,
  })

  const postLinkHomeInitiated = usePostIntegrationLinkHomeInitiatedEvent()

  const postIntegrationHomeSearched = usePostIntegrationHomeSearchedEvent()

  const postIntegrationHomeSearchedDebounced = useDebouncedCallback(() => {
    postIntegrationHomeSearched.mutate({ integration_name: integration.name })
  })

  const showNoHomes = !loading && homeLinks.length < 1

  function onCloseDialog() {
    setShowCreateLinkDialog(false)
    setShowUnlinkDialog(false)
    setUnlinkProps(undefined)
    setCreateLinkProps(undefined)
  }

  function handleSearchChange(value: string) {
    setSearchHome(value)
    if (!!value) {
      postIntegrationHomeSearchedDebounced()
    }
  }

  useTrackIntegrationViewedEvent({
    integration,
    tabId,
  })

  return (
    <div>
      <SearchFilterBox>
        <UpdateAirbnbIntegrationBanner integration={integration} />
        <GotoMultiAccountSettingsBanner integration={integration} />
        <SearchWrapper>
          <SearchFilter
            placeholder={t(langKeys.search_for_home)}
            onChange={handleSearchChange}
            delay={500}
            disabled={loading}
          />
        </SearchWrapper>
      </SearchFilterBox>
      <IntegrationConfigureBox>
        <LinkHomeTable
          sort={sort}
          setSort={setSort}
          integrationEntities={linkedIntegrationEntities}
          homeLinks={homeLinks}
          loading={loading && !showCreateLinkDialog}
          integration={integration}
          onLinkHome={({ relink, homeId, homeName }) => {
            postLinkHomeInitiated.mutate({
              context: "integration_page",
              integration_name: integration.name,
              relink,
            })
            setCreateLinkProps({ homeId, homeName, relink })
            setShowCreateLinkDialog(true)
          }}
          onRemoveLink={({ linkId, entityName, homeName }) => {
            setUnlinkProps({ linkId, entityName, homeName })
            setShowUnlinkDialog(true)
          }}
        />
        <PagerWrapper>
          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={homeLinksCount}
          />
        </PagerWrapper>
        {showNoHomes &&
          (searchHome ? (
            <NoHomesOnFilter />
          ) : (
            <NoHomes
              body={t(langKeys.integrations_create_homes_to_get_started_link)}
            />
          ))}
      </IntegrationConfigureBox>

      {createLinkProps && (
        <CreateLinkDialog
          integration={integration}
          homeName={createLinkProps.homeName}
          homeId={createLinkProps.homeId}
          relink={createLinkProps.relink}
          open={showCreateLinkDialog}
          unlinkedEntities={selectableIntegrationEntities}
          onClose={onCloseDialog}
          nextCursor={cursor}
          hasCursor={supportsPagination}
          fetchNextPage={() => fetchNextPage?.()}
          loading={loading}
          filter={searchIntegrationUnit || ""}
          setFilter={setSearchIntegrationUnit}
        />
      )}

      {unlinkProps && (
        <UnlinkDialog
          integration={integration}
          homeName={unlinkProps.homeName}
          entityName={unlinkProps.entityName}
          linkId={unlinkProps.linkId}
          open={showUnlinkDialog}
          onClose={onCloseDialog}
        />
      )}
    </div>
  )
}

const SearchFilterBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const IntegrationConfigureBox = styled.div`
  margin-top: ${spacing.M};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const SearchWrapper = styled.div`
  max-width: 30ch;
`
