import { useState } from "react"

import { checkBrokenIntegration } from "src/data/integrations/logic/integrations"
import {
  useFetchInfiniteIntegrationEntities,
  useFetchIntegrationEntities,
} from "src/data/integrations/queries/integrationEntityQueries"
import { useFetchIntegrationHomeLinks } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
  TIntegrationEntities,
  TIntegrationHomeLink,
  TIntegrationHomeLinksSort,
} from "src/data/integrations/types/integrationTypes"
const CURSOR_LIST_LIMIT = 50

export function useGetIntegrationLinksData({
  integration,
  orgId,
  supportsPagination,
  limit,
  offset,
}: {
  integration: TIntegration
  orgId: string
  supportsPagination: boolean
  limit: number
  offset: number
}) {
  const [searchHome, setSearchHome] = useState<string | undefined>(undefined)
  const [searchIntegrationUnit, setSearchIntegrationUnit] = useState<
    string | undefined
  >("")
  const [sort, setSort] = useState<TIntegrationHomeLinksSort>({
    id: "link_status",
    order: "asc",
  })

  const {
    data: homeLinks,
    isLoading: homeLinksLoading,
    count: homeLinksCount,
  } = useGetHomeLinks({
    integration,
    orgId,
    limit,
    offset,
    sort,
    searchHome,
  })

  const {
    selectableIntegrationEntities,
    linkedIntegrationEntities,
    isLoading: unlinkedIntegrationEntitiesLoading,
    cursor,
    fetchNextPage,
  } = useGetIntegrationEntities({
    integration,
    orgId,
    supportsPagination,
    searchIntegrationUnit,
    existingLinks: homeLinks.filter((link) => link.link_status !== "unlinked"),
  })

  const isLoading = homeLinksLoading || unlinkedIntegrationEntitiesLoading

  return {
    linkedIntegrationEntities,
    homeLinks,
    homeLinksCount,
    selectableIntegrationEntities,
    sort,
    setSort,
    searchHome,
    setSearchHome,
    searchIntegrationUnit,
    setSearchIntegrationUnit,
    isLoading,
    cursor,
    fetchNextPage,
  }
}

function useGetIntegrationEntities({
  integration,
  orgId,
  supportsPagination,
  searchIntegrationUnit,
  existingLinks,
}: {
  integration: TIntegration
  orgId: string
  supportsPagination: boolean
  searchIntegrationUnit: string | undefined
  existingLinks: TIntegrationHomeLink[]
}): {
  selectableIntegrationEntities: TIntegrationEntities
  linkedIntegrationEntities: TIntegrationEntities
  isLoading: boolean
  cursor?: string | undefined
  fetchNextPage?: () => void | undefined
} {
  const isBrokenIntegration = checkBrokenIntegration(integration)

  const [index, setIndex] = useState(0)

  const enablePaginatedRequests = supportsPagination && !isBrokenIntegration
  const enableNonPaginatedRequests = !supportsPagination && !isBrokenIntegration

  // This request fetches all entities since we don't support pagination
  const fetchIntegrationEntitiesNonPaginated = useFetchIntegrationEntities({
    integration: integration.integration_identifier,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
    options: {
      enabled: enableNonPaginatedRequests,
    },
  })

  const linkedHomesEntityIds = existingLinks
    .map((link) => link.entity_id)
    .filter((link) => link !== undefined)

  /**
   * The next two requests fetches entities with pagination AND another request to fetches the entities that
   * belong to the homes that are shown in the UI
   * (since they are not guaranteed to be in the first page)
   */

  // Paginated request - used to show which integration entities you can choose from when linking a home
  const fetchIntegrationEntitiesPaginated = useFetchInfiniteIntegrationEntities(
    {
      integration: integration.integration_identifier,
      orgId,
      ownerType: OwnerType.ORGANIZATION,
      filters: {
        limit: CURSOR_LIST_LIMIT,
        name: searchIntegrationUnit || undefined,
      },
      options: {
        enabled: enablePaginatedRequests,
        keepPreviousData: true,
      },
    }
  )

  // Request for the entities that belong to the homes shown in the UI - used to show which integration entities are already linked to a home
  const fetchLinkedIntegrationEntities = useFetchIntegrationEntities({
    integration: integration.integration_identifier,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
    options: {
      enabled: enablePaginatedRequests && linkedHomesEntityIds.length > 0,
      keepPreviousData: true,
    },
    entityIds: linkedHomesEntityIds,
  })

  if (supportsPagination) {
    return formatIntegrationEntitiesForPagination(
      fetchIntegrationEntitiesPaginated,
      fetchLinkedIntegrationEntities,
      linkedHomesEntityIds,
      index,
      setIndex
    )
  }

  return formatIntegrationEntitiesForNonPagination(
    fetchIntegrationEntitiesNonPaginated,
    linkedHomesEntityIds,
    searchIntegrationUnit
  )
}

function useGetHomeLinks({
  integration,
  orgId,
  limit,
  offset,
  sort,
  searchHome,
}: {
  integration: TIntegration
  orgId: string
  limit: number
  offset: number
  sort: TIntegrationHomeLinksSort
  searchHome: string | undefined
}) {
  const fetchIntegrationHomeLinks = useFetchIntegrationHomeLinks({
    integrationId: integration.integration_identifier,
    ownerId: orgId,
    ownerType: OwnerType.ORGANIZATION,
    filters: {
      limit,
      offset,
      name: searchHome || undefined,
      sort: sort.id,
      sort_order: sort.order,
    },
  })

  return {
    data: fetchIntegrationHomeLinks.data?.homes ?? [],
    isLoading: fetchIntegrationHomeLinks.isLoading,
    count: fetchIntegrationHomeLinks.data?.paging?.total_count ?? 0,
  }
}

function filterUnlinkedIntegrationEntites(
  integrationEntities: TIntegrationEntities,
  linkedEntityIds: string[]
) {
  return integrationEntities.filter(
    (entity) => !linkedEntityIds.includes(entity.entity_id)
  )
}

function filterLinkedIntegrationEntites(
  integrationEntities: TIntegrationEntities,
  linkedEntityIds: string[]
) {
  return integrationEntities.filter((entity) =>
    linkedEntityIds.includes(entity.entity_id)
  )
}

function formatIntegrationEntitiesForPagination(
  fetchIntegrationEntitiesPaginated: ReturnType<
    typeof useFetchInfiniteIntegrationEntities
  >,
  fetchLinkedIntegrationEntities: ReturnType<
    typeof useFetchIntegrationEntities
  >,
  linkedHomesEntityIds: string[],
  index: number,
  setIndex: (index: number) => void
) {
  const integrationEntities =
    fetchIntegrationEntitiesPaginated.data?.pages.flatMap((e) => e.entities) ??
    []

  // Remove entities that are already linked to a home, as they should not be shown as selectable
  const selectableIntegrationEntities = filterUnlinkedIntegrationEntites(
    integrationEntities,
    linkedHomesEntityIds
  )
  const linkedIntegrationEntities =
    fetchLinkedIntegrationEntities.data?.entities ?? []
  return {
    selectableIntegrationEntities,
    linkedIntegrationEntities,
    isLoading:
      fetchIntegrationEntitiesPaginated.isFetchingNextPage ||
      fetchLinkedIntegrationEntities.isInitialLoading,
    cursor:
      fetchIntegrationEntitiesPaginated.data?.pages[index]?.paging.next_cursor,
    fetchNextPage: () => {
      setIndex(index + 1)
      fetchIntegrationEntitiesPaginated.fetchNextPage()
    },
  }
}

function formatIntegrationEntitiesForNonPagination(
  fetchIntegrationEntities: ReturnType<typeof useFetchIntegrationEntities>,
  linkedHomesEntityIds: string[],
  searchIntegrationUnit: string | undefined
) {
  const entities = fetchIntegrationEntities.data?.entities ?? []

  const unlinkedEntities = filterUnlinkedIntegrationEntites(
    entities,
    linkedHomesEntityIds
  )

  const linkedIntegrationEntities = filterLinkedIntegrationEntites(
    entities,
    linkedHomesEntityIds
  )

  const selectableIntegrationEntities = unlinkedEntities.filter((entity) =>
    entity.name
      .toLowerCase()
      .includes(searchIntegrationUnit?.toLowerCase().trim() ?? "")
  )

  return {
    selectableIntegrationEntities,
    linkedIntegrationEntities,
    isLoading: fetchIntegrationEntities.isLoading,
  }
}
