import { useState } from "react"
import styled from "styled-components"

import { ConfiguredIntegrationsList } from "src/components/Integrations/ConfiguredIntegrationsList/ConfiguredIntegrationsList"
import { IntegrationsFilterSearch } from "src/components/Integrations/IntegrationsFilterSearch"
import { IntegrationsListContents } from "src/components/Integrations/IntegrationsListContents"
import { staticIntegrations } from "src/components/Integrations/StaticIntegrations/staticIntegrations"
import { HREF_MINUT_INTEGRATION_LET_US_KNOW } from "src/constants/hrefs"
import {
  useSendIntegrationTrackEvents,
  useTrackIntegrationSearchedEvent,
} from "src/data/integrations/hooks/useSendIntegrationEvents"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { TIntegrationFilter } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MainView } from "src/ui/Layout/MainView"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IntegrationsList() {
  const { org } = useOrganization()
  const { t, langKeys } = useTranslate()
  const [search, setSearch] = useState("")
  const [activeFilters, setActiveFilters] = useState<TIntegrationFilter[]>([])

  const fetchConfiguredIntegrations = useFetchIntegrations({
    orgId: org.id,
    filters: {
      configured: true,
      includeExternalIntegrations: true,
    },
  })

  const configuredIntegrations =
    fetchConfiguredIntegrations.data?.integrations || []

  const fetchUnconfiguredIntegrations = useFetchIntegrations({
    orgId: org.id,
    filters: {
      name: search,
      configured: false,
      includeExternalIntegrations: true,
      integrationType: activeFilters.map((i) => i.id),
    },
  })

  const unconfiguredIntegrations =
    fetchUnconfiguredIntegrations.data?.integrations ?? []

  const error =
    fetchConfiguredIntegrations.isError || fetchUnconfiguredIntegrations.isError

  const loading =
    fetchConfiguredIntegrations.isLoading ||
    fetchUnconfiguredIntegrations.isLoading

  const staticIntegrationsList = staticIntegrations(t, langKeys)

  const filteredStaticIntegrations = staticIntegrationsList
    .filter((i) => i.name.toLowerCase().includes(search.toLowerCase()))
    .filter(
      (f) =>
        activeFilters.length === 0 || activeFilters.some((s) => s.id === f.type)
    )

  const otherIntegrations = [
    ...unconfiguredIntegrations,
    ...filteredStaticIntegrations,
  ]

  useSendIntegrationTrackEvents({
    configuredIntegrations,
    isFetched: fetchConfiguredIntegrations.isFetched,
  })

  const { sendIntegrationEventDebounced } = useTrackIntegrationSearchedEvent({
    configuredIntegrations,
    isFetched: fetchConfiguredIntegrations.isFetched,
  })

  function handleSearch(searchString: string) {
    setSearch(searchString)

    if (!!searchString) {
      sendIntegrationEventDebounced()
    }
  }

  function ListSubHeading() {
    if (loading) {
      return <MSkeleton width="50ch" height={25} />
    }

    if (configuredIntegrations.length === 0) {
      return null
    }

    return (
      <MText variant="heading2">
        {t(langKeys.integrations_other_integrations_title)}
      </MText>
    )
  }

  return (
    <MainView
      title={t(langKeys.integrations_title)}
      titleBarProps={{ description: t(langKeys.integrations_description) }}
    >
      <Grid>
        <ConfiguredIntegrationsList
          configuredIntegrations={configuredIntegrations}
        />

        <ContentBox>
          <ListSubHeading />

          <IntegrationsFilterSearch
            activeFilters={activeFilters}
            onSelectFilter={setActiveFilters}
            onSearch={handleSearch}
            configuredIntegrations={configuredIntegrations}
            isFetched={fetchConfiguredIntegrations.isFetched}
          />

          <IntegrationsListContents
            integrations={otherIntegrations}
            loading={loading}
            error={error}
          />

          <LetUsKnowBox>
            {t(langKeys.integrations_did_not_see_integrations_question)}{" "}
            <ExternalLink href={HREF_MINUT_INTEGRATION_LET_US_KNOW}>
              {t(langKeys.let_us_know)}
            </ExternalLink>
          </LetUsKnowBox>
        </ContentBox>
      </Grid>
    </MainView>
  )
}

const ContentBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const LetUsKnowBox = styled.div`
  margin-top: ${spacing.L};
`

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`
