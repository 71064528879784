import { ThermostatSettings } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatSettings"
import { TThermostatSettingsAnalytics } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { useUpdateThermostatSettings } from "src/data/integrations/logic/integrations"
import { TThermostatSettings } from "src/data/integrations/types/integrationTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"

export type TSettings = {
  heatTemperature: number
  coolTemperature: number
  preHeatCoolTime: number
}

export function ThermostatSettingsDialog({
  open,
  onClose,
  thermostatSettings,
  homeId,
  trackIntegration,
}: {
  open: boolean
  onClose: () => void
  thermostatSettings: TThermostatSettings
  homeId: string
  trackIntegration: TThermostatSettingsAnalytics
}) {
  const { t } = useTranslate()

  const {
    patchHome,
    handleSave,
    temperatureControlValues,
    temperatureUnit,
    setTemperatureControlValues,
    selectedMode,
    setSelectedMode,
  } = useUpdateThermostatSettings({
    modeOnCheckin: thermostatSettings.mode,
    setPoints: thermostatSettings.setPoints,
    turnOnTime: thermostatSettings.turnOnTime,
    homeId,
    afterSaveSuccess: onClose,
    trackIntegration,
  })

  return (
    <MDialog
      title={t(langKeys.integrations_thermostat_settings_dialog_title)}
      description={t(
        langKeys.integrations_thermostat_settings_dialog_description
      )}
      open={open}
      onClose={() => {
        patchHome.reset()
        onClose()
      }}
      confirmLabel={t(langKeys.save)}
      onConfirm={handleSave}
      loading={patchHome.isLoading}
      error={
        patchHome.isError
          ? t(langKeys.failed_general_error_try_refreshing_page)
          : undefined
      }
    >
      <ThermostatSettings
        temperatureControlValues={temperatureControlValues}
        setTemperatureControlValues={setTemperatureControlValues}
        temperatureUnit={temperatureUnit}
        selectedMode={selectedMode}
        setSelectedMode={setSelectedMode}
        commonAvailableModes={thermostatSettings.commonAvailableModes}
      />
    </MDialog>
  )
}
