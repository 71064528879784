import GuestImportIcon from "src/components/Integrations/StaticIntegrations/GuestImport/guest-import.svg"
import { TIntegrationStatic } from "src/data/integrations/types/integrationTypes"
import { TLangKeys, TTranslateFunction } from "src/i18n/useTranslate"

export function staticIntegrations(
  t: TTranslateFunction,
  langKeys: TLangKeys
): TIntegrationStatic[] {
  return [
    {
      name: t(langKeys.stay_importer_title),
      integration_identifier: "guest-importer",
      summary: t(langKeys.stay_importer_subtitle),
      description: t(langKeys.stay_importer_subtitle),
      type: "pms",
      static: true,
      iconComponent: (size) => <GuestImportIcon width={size} height={size} />,
      rich_description: t(langKeys.stay_importer_instructions),
      configuration_status: "not_configured",
    },
  ]
}
